import React, { useMemo } from 'react'
import { Link, graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import { removeTags, trimMetaDescription } from '/src/util'

export const Head = ({ data }) => {
  const [product] = data.allNodeNews.edges
  const hasBody = useMemo(() => product.node.body && product.node.body.processed ? true : false, [product.node.body && product.node.body.processed])

  return (
    <>
      <title>{product.node.title} | Orrcon Steel</title>
      {hasBody && <meta name="description" content={trimMetaDescription(removeTags(product.node.body.processed))} />}
      {hasBody && <meta name="og:description" content={trimMetaDescription(removeTags(product.node.body.processed))} />}
      <meta property="og:image" content="https://orrcon-static-assets.s3.ap-southeast-2.amazonaws.com/og_orrcon_news.jpg" />
    </>
  )
}

export default ({ data }) => {
  const { body, field_news_date, field_news_type, relationships, title } = useMemo(() => {
    const [first] = data.allNodeNews.edges
    return first.node
  }, [data])

  return (
    <Layout>
      <Row>
        <Col xs={12}>
          <div className="mt-5">
            <Link className="back-to-link" to='/resources/news-updates'>
              <FontAwesomeIcon icon={faArrowLeft} />
              Back to news
            </Link>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center content-row">
        <Col xs={10}>
          <div className="title-container mb-5">
            <h1 className="title">{title}</h1>
          </div>

          <Row>
            <Col xs={6}>
              {field_news_date}
            </Col>
            <Col xs={6}>
              
            </Col>
          </Row>

          {body && (
            <div className="body news-body mb-2" dangerouslySetInnerHTML={{
              __html: body.processed
            }}></div>
          )}
        </Col>
      </Row>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    allNodeNews(
      sort: {fields: field_news_date, order: DESC}
      filter: {drupal_id: {eq: $id}}
    ) {
      edges {
        node {
          path {
            alias
          }
          field_news_type
          relationships {
            field_news_image {
              relationships {
                field_media_image_1 {
                  gatsbyImage(
                    fit: COVER
                    formats: AUTO
                    layout: FIXED
                    quality: 100
                    placeholder: BLURRED
                    width: 240
                  )
                }
              }
            }
          }
          field_news_date
          body {
            value
            processed
          }
          title
        }
      }
    }
  }
`
